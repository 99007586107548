@import '../../../variables';

.container {
  position: fixed;
  top: 0;
  height: 100vh;
  background-color: #6bc5da;
  z-index: 2;

  .details {
    display: flex;
    flex-direction: column;
    align-content: flex-start;

    h1 {
      margin-top: 8vw;
      margin-bottom: 3.7vw;
      font-size: 11.3vw;
      text-align: center;
      letter-spacing: 0.55vw;
      line-height: 9vw;
    }

    .brush {
      height: 8vw;
      margin: auto;
      padding-bottom: 3vw;
    }

    .description {
      display: flex;
      flex-direction: column;
      margin-top: 3vw;
      font-size: 5vw;
      letter-spacing: 0.3vw;
      height: 78vh;
      overflow: auto;

      padding-bottom: 5vh;

      h2 {
        font-size: 7vw;
        font-weight: 600;
      }

      section {
        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;

        -ms-word-break: break-all;
        word-break: break-word;

        /* Adds a hyphen where the word breaks, if supported (No Blink) */
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;

        margin-bottom: 5vh;

        h3 {
          font-size: 6vw;
          font-weight: 600;
        }

        h4 {
          font-size: 5.5vw;
          font-weight: 600;
          margin-top: 2vh;
        }

        span {
          color: #ebe2cd;
          font-size: 5vw;
          letter-spacing: 0.3vw;
          margin-top: 3vw;
        }

        a {
          color: #ffffff;
        }
      }
    }
  }
}
