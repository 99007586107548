.container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .row {
    height: 100vh;

    .transition-enter {
      transform: translateX(-100%);
      z-index: 1000;
    }

    .transition-enter-active {
      transform: translateX(0);
      transition: transform 500ms;
      z-index: 1000;
    }

    .transition-enter-done {
      z-index: 1000;
    }

    .transition-exit {
      transform: translateX(0);
      z-index: 1000;
    }

    .transition-exit-active {
      transform: translateX(-100%);
      transition: transform 500ms;
      z-index: 1000;
    }

    .transition-exit-done {
      z-index: 0;
    }

    .close {
      position: absolute;
      top: 1.5vh;
      right: 1.5vh;
      height: 2.5vh;

      &:hover {
        cursor: pointer;
      }
    }

    .overlay {
      z-index: 1000;
    }
  }
}
