.container {
  color: #6bc5da;
  padding-bottom: 4.7vw;

  h1 {
    display: flex;
    justify-content: center;
    margin-top: 3vw;
    margin-bottom: 4.7vw;
    font-size: 11.3vw;
    letter-spacing: 0.55vw;
    line-height: 11vw;
  }

  p {
    display: flex;
    justify-content: space-between;
    font-size: 8vw;
    text-align: center;
    line-height: 7vw;
    margin-bottom: 2vw;

    &:last-of-type {
      justify-content: center;
      font-size: 5vw;
      letter-spacing: 0.3vw;
    }

    span {
      font-size: 4.6vw;
      letter-spacing: 0.3vw;
      line-height: 9vw;

      &:first-of-type {
        float: left;
      }

      &:last-of-type {
        float: right;
      }

      &.hidden {
        color: #ffffff;
      }
    }
  }

  .brush {
    display: flex;
    justify-content: center;
    width: 26vw;
    margin: 4vw auto 3vw;

    &:hover {
      cursor: pointer;
    }
  }
}
