@import '../../../variables';

.container {
  padding-top: 8vw;

  .image:hover {
    cursor: pointer;
  }

  .image:first-of-type {
    float: left;
    height: 35vw;
    border-radius: 12%;
    -webkit-border-radius: 12%;
    -moz-border-radius: 12%;
    box-shadow: $box-shadow-1;
  }

  .image:last-of-type {
    float: right;
    height: 34.5vw;
  }
}
