.container {
  h1 {
    margin-top: 0;
    margin-bottom: 4.7vw;
    font-size: 11.3vw;
    text-align: center;
    letter-spacing: 0.55vw;
    line-height: 9vw;
  }

  ul {
    font-size: 5vw;
    letter-spacing: 0.3vw;
    text-align: center;

    li {
      margin-bottom: 4vw;
      line-height: 5.5vw;

      span {
        color: #ebe2cd;
      }

      .brush {
        width: 31vw;
        margin: auto;
        padding-bottom: 1vw;
        padding-top: 1vw;
      }
    }
  }
}
