.container {
  background-color: #ffffff;
  display: flex;
  justify-content: center;

  .jameda {
    position: relative;
    width: 272px;
    height: 168px;
    margin: 15px;
    background-image: url(https://cdn1.jameda-elements.de/premium/widgets/_images/top10-top20-bg.png);
    background-repeat: no-repeat;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    line-height: 15px;

    a {
      display: block;
      position: absolute;
      top: 100px;
      left: 10px;
      width: 70px;
      height: 20px;
      background-color: transparent !important;
    }
  }

  .jambw {
    position: absolute;
    top: 11px;
    left: 108px;
    width: 152px;
    height: 88px;
    overflow: hidden;
    white-space: normal;

    .loading {
      position: absolute;
      top: 32px;
      left: 0px;
      color: #666;
    }
  }

  .content {
    position: absolute;
    top: 111px;
    left: 108px;
    width: 152px;
    height: 40px;
    overflow: hidden;
    font-size: 11px;
    line-height: 13px;

    .link {
      color: #888;
      text-decoration: none;
      border: 0px;
      background-color: transparent !important;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
