@import '../../../variables';

.container {
  height: 100vh;
  background-color: #e7dcc2;
  box-shadow: $box-shadow-2;

  .details {
    position: absolute;
    right: 4.5vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-content: flex-start;

    h1 {
      padding-top: 2vh;
      font-size: 5.8vh;
      letter-spacing: 0.44vh;
      text-align: center;
      margin: 0;
    }

    .brush {
      align-self: center;
      padding-top: 0.4vh;
      padding-bottom: 2vh;
      width: 18vh;
    }

    .carousel {
      .member {
        outline: none !important;

        &:hover {
          cursor: grab;
        }

        h3 {
          margin-top: 1.8vh;
          text-align: center;
          font-size: 2.9vh;
          letter-spacing: 0.12vh;

          span {
            color: #ebe2cd;
          }
        }

        img {
          width: 20vh;
          margin: auto;
          padding: 0;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          box-shadow: $box-shadow-1;
          border-color: white;
          border-style: solid;
          border-width: 1.4vh;
        }
      }
    }

    .description-carousel {
      .member {
        outline: none !important;

        .description {
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 2vh;
          letter-spacing: 0.12vh;

          margin-top: 3vh;
          text-align: justify;
          color: #60b3c6;

          ul {
            list-style: none;
            margin-top: 3vh;
            text-align: center;
            padding-left: 0;
            font-size: 2vh;

            &.justify {
              text-align: justify;
            }

            li {
              margin-bottom: 1.5vh;
              line-height: 2.2vh;

              &:first-of-type {
                margin-bottom: 6vh;
                font-size: 2.1vh;
                text-align: center;
              }
            }
          }

          span {
            color: #ebe2cd;
            font-size: 1.9vh;
            letter-spacing: 0.12vh;
            margin-top: 1.9vh;
          }

          a {
            color: #ffffff;
          }
        }
      }
    }
  }
}
