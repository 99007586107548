.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: #bcbcbc;
  margin-top: 0;
  padding-top: 4vw;
  padding-bottom: 4vw;
  height: inherit;
  font-size: 4vw;

  .meta {
    color: #fff;
    display: flex;
    flex-direction: row;

    div {
      text-decoration: none;
      margin-right: 20px;

      &:hover {
        text-decoration: none;
        cursor: pointer;
      }
    }
  }

  img {
    display: inline-block;
    height: 5.5vw;
    margin-left: 2vw;
  }

  span {
    display: inline-block;
    font-size: 1.5vh;
    line-height: inherit;
    text-align: inherit;
    margin-bottom: inherit;
    margin-right: 1vh;
  }
}

.close {
  position: absolute;
  top: 5vw;
  right: 5vw;
  height: 6vw;
  z-index: 10;
}