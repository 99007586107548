@import '../../../variables';

.container {
  margin-top: 0;
  margin-bottom: 10vw;
  font-size: 11.3vw;
  text-align: center;
  letter-spacing: 0.55vw;
  line-height: 9vw;

  h1 {
    margin-top: 0;
    margin-bottom: 4.7vw;
    font-size: 11.3vw;
    text-align: center;
    letter-spacing: 0.55vw;
    line-height: 9vw;
  }

  .carousel {
    .member {
      outline: none !important;

      &:hover {
        cursor: grab;
      }

      h3 {
        margin-top: 1.8vh;
        text-align: center;
        font-size: 2.9vh;
        letter-spacing: 0.12vh;

        span {
          color: #ebe2cd;
        }
      }

      img {
        width: 61vw;
        margin: auto;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-color: white;
        border-style: solid;
        border-width: 2vw;
        box-shadow: $box-shadow-1;
      }

      .description {
        h2 {
          margin-top: 3vw;
          margin-bottom: 4.7vw;
          font-size: 7.3vw;
          text-align: center;
        }

        h3 {
          margin-bottom: 4vw;
          font-size: 5vw;
          letter-spacing: 0.3vw;
          text-align: center;
          color: #6bc5da;
        }
      }
    }
  }
}
