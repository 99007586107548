@import '../../../variables';

.container {
  height: 100vh;
  background-color: #6bc5da;
  box-shadow: $box-shadow-2;

  .details {
    position: absolute;
    right: 4.5vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-content: flex-start;

    h1 {
      padding-top: 2vh;
      font-size: 5.8vh;
      letter-spacing: 0.44vh;
      text-align: center;
      margin: 0;
    }

    .brush {
      align-self: center;
      padding-top: 0.4vh;
      padding-bottom: 2vh;
      width: 17vh;
    }

    .carousel {
      .service {
        outline: none !important;

        &:hover {
          cursor: grab;
        }

        h3 {
          margin-top: 1.8vh;
          text-align: center;
          font-size: 2.9vh;
          letter-spacing: 0.12vh;

          span {
            color: #ebe2cd;
          }
        }

        img {
          width: 20vh;
          margin: auto;
          padding: 0;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          box-shadow: $box-shadow-1;
        }
      }
    }

    .description-carousel {
      .service {
        outline: none !important;

        .description {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-size: 2vh;
          letter-spacing: 0.12vh;

          margin-top: 6vh;
          text-align: justify;

          ul {
            margin-top: 3vh;
            padding-left: 25px;

            li {
              margin-bottom: 1.5vh;
              line-height: 2.2vh;
            }
          }

          span {
            color: #ebe2cd;
            font-size: 1.9vh;
            letter-spacing: 0.12vh;
            margin-top: 1.9vh;
          }

          a {
            color: #ffffff;
          }
        }
      }
    }
  }
}
