.container {
  display: flex;
  flex-direction: column;
  color: #6bc5da;

  h1 {
    margin-top: 0;
    margin-bottom: 4.7vw;
    font-size: 11.3vw;
    text-align: center;
    letter-spacing: 0.55vw;
    line-height: 9vw;
  }

  .brush {
    width: 22vw;
    margin: auto;
    padding-bottom: 3vw;
    padding-top: 4vw;
  }

  .carousel {
    margin-bottom: 1vw;
    font-size: 4.4vw;
    letter-spacing: 0.1vw;
    text-align: center;
    line-height: 7vw;

    .advice {
      outline: none !important;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
