.container {
  .logo {
    background-color: #ffffff;
  }

  .jameda {
    background-color: #ffffff;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .appointment {
    background-color: #ffffff;
  }

  .hours {
    background-color: #ffffff;
  }

  .status {
    background-color: #ffffff;
  }

  .services {
    background-color: #6bc5da;
  }

  .team {
    background-color: #e7dcc2;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .advices {
    background-color: #ffffff;
  }

  .contact {
    background-color: #bcbcbc;
  }

  .footer {
    background-color: #bcbcbc;
  }
}
