.container {
  .first {
    height: 33.8vh;

    .services {
      background-color: #6bc5da;
    }

    .logo {
      background-color: #ffffff;
    }
  }

  .second {
    height: 18.6vh;

    .team {
      background-color: #e7dcc2;
    }

    .appointment {
      background-color: #ffffff;
    }
  }

  .third {
    height: 12.9vh;

    .advices {
      background-color: #ffffff;
    }

    .status {
      background-color: #ffffff;
    }
  }

  .fourth {
    height: 34.7vh;

    .contact {
      background-color: #bcbcbc;
    }

    .hours {
      background-color: #ffffff;
    }
  }


  .panel-enter {
    z-index: 10;
    opacity: 0;
    transform: scale(1.1);
  }

  .panel-enter-active {
    z-index: 10;
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }

  .panel-exit {
    opacity: 1;
    transform: scale(1);
  }

  .panel-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
}
