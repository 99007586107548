@import '../../../variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;

  h1 {
    margin-top: 0;
    margin-bottom: 4.7vw;
    font-size: 11.3vw;
    text-align: center;
    letter-spacing: 0.55vw;
    line-height: 9vw;
  }

  .facebook {
    display: inline-block;
    width: 5.5vw;
    vertical-align: text-bottom;
    border-color: white;
    border-style: solid;
    border-width: 0.5vw;
    margin-bottom: 4.7vw;
  }

  .info {
    font-size: 2.1vh;
    letter-spacing: 0.12vh;

    margin-bottom: 4.7vw;

    p {
      font-size: 5vw;
      letter-spacing: 0.3vw;
      text-align: center;
      margin-bottom: 0;
    }

    a:link,
    a:visited,
    a:hover,
    a:active {
      color: #ffffff;
    }
  }

  .brush {
    margin-bottom: 3.5vw;
    height: 4vw;
  }

  .map {
    height: 25vw;
    -webkit-border-radius: 12%;
    -moz-border-radius: 12%;
    border-radius: 12%;
    box-shadow: $box-shadow-1;
  }

  .directions {
    display: flex;
    flex-direction: row;
    align-self: start;

    ul {
      display: flex;
      flex-direction: column;
      align-items: start;

      font-size: 5vw;
      letter-spacing: 0.3vw;
      text-align: center;

      margin-top: 8vw;

      li {
        display: flex;
        flex-direction: row;
        align-items: center;

        margin-bottom: 3vw;
        line-height: 5.5vw;

        span {
          display: inline-block;
          line-height: normal;
          vertical-align: middle;

          &:first-of-type {
            display: inline-block;
            width: 6vw;
            height: 6vw;
            line-height: 6vw;
            font-size: 5vw;
            background-color: #6bc5da;
            vertical-align: middle;
            text-align: center;
            border-color: white;
            border-style: solid;
            border-width: 0.5vw;
          }

          &:last-of-type {
            display: inline-block;
            margin-left: 2vw;
            line-height: normal;
            vertical-align: middle;
            text-align: start;
            font-size: 4vw;
            letter-spacing: 0.2vw;
          }
        }
      }
    }
  }


}
