.container {
  position: absolute;
  top: 0;
  height: 100vh;
  left: 0;
  right: 0;
  margin: auto;
  background: #6bc5da;
  z-index: 2;

  .overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: rgb(156, 216, 230); /* Old browsers */
    background: -moz-radial-gradient(
      center,
      ellipse cover,
      rgba(156, 216, 230, 1) 0%,
      rgba(107, 197, 218, 1) 44%,
      rgba(107, 197, 218, 1) 44%
    ); /* FF3.6+ */
    background: -webkit-gradient(
      radial,
      center center,
      0px,
      center center,
      100%,
      color-stop(0%, rgba(156, 216, 230, 1)),
      color-stop(44%, rgba(107, 197, 218, 1)),
      color-stop(44%, rgba(107, 197, 218, 1))
    ); /* Chrome,Safari4+ */
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      rgba(156, 216, 230, 1) 0%,
      rgba(107, 197, 218, 1) 44%,
      rgba(107, 197, 218, 1) 44%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-radial-gradient(
      center,
      ellipse cover,
      rgba(156, 216, 230, 1) 0%,
      rgba(107, 197, 218, 1) 44%,
      rgba(107, 197, 218, 1) 44%
    ); /* Opera 12+ */
    background: -ms-radial-gradient(
      center,
      ellipse cover,
      rgba(156, 216, 230, 1) 0%,
      rgba(107, 197, 218, 1) 44%,
      rgba(107, 197, 218, 1) 44%
    ); /* IE10+ */
    background: radial-gradient(
      ellipse at center,
      rgba(156, 216, 230, 1) 0%,
      rgba(107, 197, 218, 1) 44%,
      rgba(107, 197, 218, 1) 44%
    ); /* W3C */

    -webkit-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    z-index: 2;
  }

  .logo {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    height: 16vh;
    filter: drop-shadow(1px 1px 4px rgba(107, 197, 218, 0.76));
    z-index: 3;
  }
}
