body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: 'Questrial', sans-serif;
  color: #ffffff;
  user-select: none;
  cursor: default;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.42857143;
}

@media only screen and (max-width: 1224px) {
  body {
    overflow: scroll;
  }
}

/* bootstrap modifications */
div[class*='col-md'] {
  height: 100%;
  padding-right: 0;
  padding-left: 0;
}

div[class*='col-sm'] {
  padding-right: 9vw;
  padding-left: 9vw;
}

/* slick modifications */
.slick-dots li {
  margin: 0 10px;
}

.slick-dots li button:before {
  font-size: 13px;
  opacity: 1;
  color: #ffffff;
}

.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #fe9948;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  color: #fe9948;
}

.slick-prev:before, .slick-next:before {
  color: #bcbcbc;
}