.container {
  background-color: #ffffff;
  display: flex;

  .image {
    width: 28vw;
    margin: 5vw auto auto;
  }

  .shake {
    display: inline-block;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
  }

  .shake:hover {
    -webkit-animation-name: shake-rotate;
    -ms-animation-name: shake-rotate;
    animation-name: shake-rotate;
    -webkit-animation-duration: 100ms;
    -ms-animation-duration: 100ms;
    animation-duration: 100ms;
    -webkit-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -ms-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    -webkit-animation-delay: 0s;
    -ms-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation-play-state: running;
    -ms-animation-play-state: running;
    animation-play-state: running;
  }

  @-webkit-keyframes shake-rotate {
    0% {
      -webkit-transform: translate(0px, 0px) rotate(0deg);
    }
    2% {
      -webkit-transform: translate(0px, 0px) rotate(-5.5deg);
    }
    4% {
      -webkit-transform: translate(0px, 0px) rotate(1.5deg);
    }
    6% {
      -webkit-transform: translate(0px, 0px) rotate(-7.5deg);
    }
    8% {
      -webkit-transform: translate(0px, 0px) rotate(-7.5deg);
    }
    10% {
      -webkit-transform: translate(0px, 0px) rotate(-0.5deg);
    }
    12% {
      -webkit-transform: translate(0px, 0px) rotate(-5.5deg);
    }
    14% {
      -webkit-transform: translate(0px, 0px) rotate(-4.5deg);
    }
    16% {
      -webkit-transform: translate(0px, 0px) rotate(0.5deg);
    }
    18% {
      -webkit-transform: translate(0px, 0px) rotate(3.5deg);
    }
    20% {
      -webkit-transform: translate(0px, 0px) rotate(-6.5deg);
    }
    22% {
      -webkit-transform: translate(0px, 0px) rotate(-5.5deg);
    }
    24% {
      -webkit-transform: translate(0px, 0px) rotate(3.5deg);
    }
    26% {
      -webkit-transform: translate(0px, 0px) rotate(-0.5deg);
    }
    28% {
      -webkit-transform: translate(0px, 0px) rotate(-0.5deg);
    }
    30% {
      -webkit-transform: translate(0px, 0px) rotate(5.5deg);
    }
    32% {
      -webkit-transform: translate(0px, 0px) rotate(3.5deg);
    }
    34% {
      -webkit-transform: translate(0px, 0px) rotate(-5.5deg);
    }
    36% {
      -webkit-transform: translate(0px, 0px) rotate(-0.5deg);
    }
    38% {
      -webkit-transform: translate(0px, 0px) rotate(-1.5deg);
    }
    40% {
      -webkit-transform: translate(0px, 0px) rotate(4.5deg);
    }
    42% {
      -webkit-transform: translate(0px, 0px) rotate(6.5deg);
    }
    44% {
      -webkit-transform: translate(0px, 0px) rotate(0.5deg);
    }
    46% {
      -webkit-transform: translate(0px, 0px) rotate(4.5deg);
    }
    48% {
      -webkit-transform: translate(0px, 0px) rotate(-0.5deg);
    }
    50% {
      -webkit-transform: translate(0px, 0px) rotate(0.5deg);
    }
    52% {
      -webkit-transform: translate(0px, 0px) rotate(-7.5deg);
    }
    54% {
      -webkit-transform: translate(0px, 0px) rotate(-1.5deg);
    }
    56% {
      -webkit-transform: translate(0px, 0px) rotate(0.5deg);
    }
    58% {
      -webkit-transform: translate(0px, 0px) rotate(6.5deg);
    }
    60% {
      -webkit-transform: translate(0px, 0px) rotate(-3.5deg);
    }
    62% {
      -webkit-transform: translate(0px, 0px) rotate(-6.5deg);
    }
    64% {
      -webkit-transform: translate(0px, 0px) rotate(1.5deg);
    }
    66% {
      -webkit-transform: translate(0px, 0px) rotate(-0.5deg);
    }
    68% {
      -webkit-transform: translate(0px, 0px) rotate(2.5deg);
    }
    70% {
      -webkit-transform: translate(0px, 0px) rotate(-4.5deg);
    }
    72% {
      -webkit-transform: translate(0px, 0px) rotate(-1.5deg);
    }
    74% {
      -webkit-transform: translate(0px, 0px) rotate(-6.5deg);
    }
    76% {
      -webkit-transform: translate(0px, 0px) rotate(3.5deg);
    }
    78% {
      -webkit-transform: translate(0px, 0px) rotate(-5.5deg);
    }
    80% {
      -webkit-transform: translate(0px, 0px) rotate(1.5deg);
    }
    82% {
      -webkit-transform: translate(0px, 0px) rotate(4.5deg);
    }
    84% {
      -webkit-transform: translate(0px, 0px) rotate(-1.5deg);
    }
    86% {
      -webkit-transform: translate(0px, 0px) rotate(-2.5deg);
    }
    88% {
      -webkit-transform: translate(0px, 0px) rotate(-3.5deg);
    }
    90% {
      -webkit-transform: translate(0px, 0px) rotate(-0.5deg);
    }
    92% {
      -webkit-transform: translate(0px, 0px) rotate(-0.5deg);
    }
    94% {
      -webkit-transform: translate(0px, 0px) rotate(-6.5deg);
    }
    96% {
      -webkit-transform: translate(0px, 0px) rotate(-7.5deg);
    }
    98% {
      -webkit-transform: translate(0px, 0px) rotate(6.5deg);
    }
  }

  @-ms-keyframes shake-rotate {
    0% {
      -ms-transform: translate(0px, 0px) rotate(0deg);
    }
    2% {
      -ms-transform: translate(0px, 0px) rotate(0.5deg);
    }
    4% {
      -ms-transform: translate(0px, 0px) rotate(5.5deg);
    }
    6% {
      -ms-transform: translate(0px, 0px) rotate(-3.5deg);
    }
    8% {
      -ms-transform: translate(0px, 0px) rotate(-2.5deg);
    }
    10% {
      -ms-transform: translate(0px, 0px) rotate(-6.5deg);
    }
    12% {
      -ms-transform: translate(0px, 0px) rotate(6.5deg);
    }
    14% {
      -ms-transform: translate(0px, 0px) rotate(5.5deg);
    }
    16% {
      -ms-transform: translate(0px, 0px) rotate(-0.5deg);
    }
    18% {
      -ms-transform: translate(0px, 0px) rotate(-6.5deg);
    }
    20% {
      -ms-transform: translate(0px, 0px) rotate(6.5deg);
    }
    22% {
      -ms-transform: translate(0px, 0px) rotate(3.5deg);
    }
    24% {
      -ms-transform: translate(0px, 0px) rotate(-4.5deg);
    }
    26% {
      -ms-transform: translate(0px, 0px) rotate(4.5deg);
    }
    28% {
      -ms-transform: translate(0px, 0px) rotate(-0.5deg);
    }
    30% {
      -ms-transform: translate(0px, 0px) rotate(-5.5deg);
    }
    32% {
      -ms-transform: translate(0px, 0px) rotate(-6.5deg);
    }
    34% {
      -ms-transform: translate(0px, 0px) rotate(1.5deg);
    }
    36% {
      -ms-transform: translate(0px, 0px) rotate(-3.5deg);
    }
    38% {
      -ms-transform: translate(0px, 0px) rotate(0.5deg);
    }
    40% {
      -ms-transform: translate(0px, 0px) rotate(-3.5deg);
    }
    42% {
      -ms-transform: translate(0px, 0px) rotate(-5.5deg);
    }
    44% {
      -ms-transform: translate(0px, 0px) rotate(-0.5deg);
    }
    46% {
      -ms-transform: translate(0px, 0px) rotate(-7.5deg);
    }
    48% {
      -ms-transform: translate(0px, 0px) rotate(1.5deg);
    }
    50% {
      -ms-transform: translate(0px, 0px) rotate(0.5deg);
    }
    52% {
      -ms-transform: translate(0px, 0px) rotate(-4.5deg);
    }
    54% {
      -ms-transform: translate(0px, 0px) rotate(0.5deg);
    }
    56% {
      -ms-transform: translate(0px, 0px) rotate(-4.5deg);
    }
    58% {
      -ms-transform: translate(0px, 0px) rotate(2.5deg);
    }
    60% {
      -ms-transform: translate(0px, 0px) rotate(-2.5deg);
    }
    62% {
      -ms-transform: translate(0px, 0px) rotate(4.5deg);
    }
    64% {
      -ms-transform: translate(0px, 0px) rotate(-1.5deg);
    }
    66% {
      -ms-transform: translate(0px, 0px) rotate(-5.5deg);
    }
    68% {
      -ms-transform: translate(0px, 0px) rotate(0.5deg);
    }
    70% {
      -ms-transform: translate(0px, 0px) rotate(-3.5deg);
    }
    72% {
      -ms-transform: translate(0px, 0px) rotate(-2.5deg);
    }
    74% {
      -ms-transform: translate(0px, 0px) rotate(-1.5deg);
    }
    76% {
      -ms-transform: translate(0px, 0px) rotate(-1.5deg);
    }
    78% {
      -ms-transform: translate(0px, 0px) rotate(6.5deg);
    }
    80% {
      -ms-transform: translate(0px, 0px) rotate(3.5deg);
    }
    82% {
      -ms-transform: translate(0px, 0px) rotate(1.5deg);
    }
    84% {
      -ms-transform: translate(0px, 0px) rotate(-0.5deg);
    }
    86% {
      -ms-transform: translate(0px, 0px) rotate(2.5deg);
    }
    88% {
      -ms-transform: translate(0px, 0px) rotate(-6.5deg);
    }
    90% {
      -ms-transform: translate(0px, 0px) rotate(2.5deg);
    }
    92% {
      -ms-transform: translate(0px, 0px) rotate(6.5deg);
    }
    94% {
      -ms-transform: translate(0px, 0px) rotate(-7.5deg);
    }
    96% {
      -ms-transform: translate(0px, 0px) rotate(6.5deg);
    }
    98% {
      -ms-transform: translate(0px, 0px) rotate(-4.5deg);
    }
  }

  @keyframes shake-rotate {
    0% {
      transform: translate(0px, 0px) rotate(0deg);
    }
    2% {
      transform: translate(0px, 0px) rotate(2.5deg);
    }
    4% {
      transform: translate(0px, 0px) rotate(-4.5deg);
    }
    6% {
      transform: translate(0px, 0px) rotate(2.5deg);
    }
    8% {
      transform: translate(0px, 0px) rotate(4.5deg);
    }
    10% {
      transform: translate(0px, 0px) rotate(-5.5deg);
    }
    12% {
      transform: translate(0px, 0px) rotate(-3.5deg);
    }
    14% {
      transform: translate(0px, 0px) rotate(-1.5deg);
    }
    16% {
      transform: translate(0px, 0px) rotate(-1.5deg);
    }
    18% {
      transform: translate(0px, 0px) rotate(2.5deg);
    }
    20% {
      transform: translate(0px, 0px) rotate(-0.5deg);
    }
    22% {
      transform: translate(0px, 0px) rotate(6.5deg);
    }
    24% {
      transform: translate(0px, 0px) rotate(0.5deg);
    }
    26% {
      transform: translate(0px, 0px) rotate(-5.5deg);
    }
    28% {
      transform: translate(0px, 0px) rotate(-4.5deg);
    }
    30% {
      transform: translate(0px, 0px) rotate(-1.5deg);
    }
    32% {
      transform: translate(0px, 0px) rotate(2.5deg);
    }
    34% {
      transform: translate(0px, 0px) rotate(0.5deg);
    }
    36% {
      transform: translate(0px, 0px) rotate(6.5deg);
    }
    38% {
      transform: translate(0px, 0px) rotate(-6.5deg);
    }
    40% {
      transform: translate(0px, 0px) rotate(3.5deg);
    }
    42% {
      transform: translate(0px, 0px) rotate(-4.5deg);
    }
    44% {
      transform: translate(0px, 0px) rotate(5.5deg);
    }
    46% {
      transform: translate(0px, 0px) rotate(2.5deg);
    }
    48% {
      transform: translate(0px, 0px) rotate(2.5deg);
    }
    50% {
      transform: translate(0px, 0px) rotate(5.5deg);
    }
    52% {
      transform: translate(0px, 0px) rotate(-1.5deg);
    }
    54% {
      transform: translate(0px, 0px) rotate(5.5deg);
    }
    56% {
      transform: translate(0px, 0px) rotate(-7.5deg);
    }
    58% {
      transform: translate(0px, 0px) rotate(2.5deg);
    }
    60% {
      transform: translate(0px, 0px) rotate(6.5deg);
    }
    62% {
      transform: translate(0px, 0px) rotate(1.5deg);
    }
    64% {
      transform: translate(0px, 0px) rotate(-7.5deg);
    }
    66% {
      transform: translate(0px, 0px) rotate(-2.5deg);
    }
    68% {
      transform: translate(0px, 0px) rotate(3.5deg);
    }
    70% {
      transform: translate(0px, 0px) rotate(-4.5deg);
    }
    72% {
      transform: translate(0px, 0px) rotate(2.5deg);
    }
    74% {
      transform: translate(0px, 0px) rotate(2.5deg);
    }
    76% {
      transform: translate(0px, 0px) rotate(-2.5deg);
    }
    78% {
      transform: translate(0px, 0px) rotate(-0.5deg);
    }
    80% {
      transform: translate(0px, 0px) rotate(-3.5deg);
    }
    82% {
      transform: translate(0px, 0px) rotate(5.5deg);
    }
    84% {
      transform: translate(0px, 0px) rotate(-1.5deg);
    }
    86% {
      transform: translate(0px, 0px) rotate(-1.5deg);
    }
    88% {
      transform: translate(0px, 0px) rotate(4.5deg);
    }
    90% {
      transform: translate(0px, 0px) rotate(5.5deg);
    }
    92% {
      transform: translate(0px, 0px) rotate(4.5deg);
    }
    94% {
      transform: translate(0px, 0px) rotate(-1.5deg);
    }
    96% {
      transform: translate(0px, 0px) rotate(3.5deg);
    }
    98% {
      transform: translate(0px, 0px) rotate(-7.5deg);
    }
  }
}
