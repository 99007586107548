@import '../../../variables';

.container {
  height: 100vh;
  background-color: #bcbcbc;
  box-shadow: $box-shadow-2;

  .details {
    position: absolute;
    right: 4.5vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-content: flex-start;

    h1 {
      padding-top: 2vh;
      font-size: 5.8vh;
      letter-spacing: 0.44vh;
      text-align: center;
      margin: 0;
    }

    .brush {
      align-self: center;
      padding-top: 0.4vh;
      padding-bottom: 2vh;
      width: 15vh;
    }

    .description {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 2vh;
      letter-spacing: 0.12vh;

      margin-top: 2vh;
    }
  }

  .canvas {
    position: absolute;
    top: 22vh;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.logo {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  color: red;
}
